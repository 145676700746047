import API from "../api/api";
import { ActionType, Device } from "../model/model";

// Load devices
export function loadDevices() {
  return (dispatch: Function, getState: Function) => {
    dispatch({ type: ActionType.LOAD_DEVICES });

    API.loadDevices()
      .then((devices: Device[]) => {
        dispatch({ type: ActionType.DEVICES_LOADED, payload: devices });
      })
      .catch((error: any) => {
        dispatch({ type: ActionType.DEVICES_FAILD_TO_LOAD, payload: error });
      });
  };
}

// Delete device
export function updateDevice(deviceId: string, eventboxId: string) {
  return (dispatch: Function, getState: Function) => {
    API.updateDevice(deviceId, eventboxId)
      .then(() => {
        dispatch({
          type: ActionType.DEVICE_UPDATED,
          payload: {
            deviceId,
            eventboxId,
          },
        });
      })
      .catch((error: any) => {});
  };
}

// Delete device
export function deleteDevice(deviceId: string) {
  return (dispatch: Function, getState: Function) => {
    API.deleteDevice(deviceId)
      .then(() => {
        dispatch(loadDevices());
      })
      .catch((error: any) => {});
  };
}

// Delete device
export function registerDevice(deviceId: string, signature: string) {
  return (dispatch: Function, getState: Function) => {
    API.registerDevice(deviceId, signature)
      .then(() => {
        dispatch(loadDevices());
      })
      .catch((error: any) => {});
  };
}
