// prettier-ignore
import * as React from "react";
import { Route } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { LoginPage } from "./pages/LoginPage";
import { CoronaWarningsPage } from "./pages/CoronaWarningsPage";
import { CoronaWarningsDetailPage } from "./pages/CoronaWarningsDetailPage";
import { DeviceManagementPage } from "./pages/DeviceManagement";
import { CompanyManagement } from "./pages/CompanyManagement";
import { CompanyDetailPage } from "./pages/CompanyDetailPage";
import { HubManagementPage } from "./pages/HubManagementPage";
import { StatsPage } from "./pages/Stats";

export function Routes() {
	const classes = useStyles();
	
	return (
		<div className={classes.content}>
			<Route exact={true} path="/" component={StatsPage} />
			<Route exact={true} path="/login" component={LoginPage} />
			<Route exact={true} path="/devices" component={DeviceManagementPage} />
			<Route exact={true} path="/hubs" component={HubManagementPage} />
			<Route exact={true} path="/coronawarning" component={CoronaWarningsPage} />
			<Route exact={true} path="/coronawarning/:id" component={CoronaWarningsDetailPage} />
			<Route exact={true} path="/companies" component={CompanyManagement} />
			<Route exact={true} path="/company/:id" component={CompanyDetailPage} />
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	content: {
		backgroundColor: '#fff',
		width: "100%",
		height: "calc(100% - 80px)",
		margin: 40,
		marginLeft: 0,
		borderRadius: 16,
		boxShadow: "5px 5px 15px 0px rgba(0,0,0,0.15)",
		[theme.breakpoints.up("sm")]: {
			height: "calc(100vh - 100px)",
			margin: 50,
			marginLeft: 0,
			overflow: 'scroll'
		},
	},
}));