// prettier-ignore
import { Dialog, DialogTitle, Theme, Box, Button, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import * as React from "react";

interface Props {
  open: boolean;
  onClose: (safediId: string, eventboxId?: string) => void;
  eventboxId: string;
  safediId: string;
}

export function EditPlaceIdDialog(props: Props) {
  const { open, onClose, eventboxId, safediId } = props;
  const [selectedHub, setSelectedHub] = React.useState("01");
  const [rowLetter, setRowLetter] = React.useState("A");
  const [row, setRow] = React.useState("1");

  const possibleHubs = [];
  for (let i = 1; i <= 300; i++) {
    let num = String(i);
    if (i < 10) {
      num = "0" + num;
    }
    possibleHubs.push(num);
  }

  const possibleLetters = ["A", "B", "C", "D", "E", "F"];

  const possibleRows = [];
  for (let i = 1; i <= 45; i++) {
    possibleRows.push(String(i));
  }

  React.useEffect(() => {
    let posFound = -1;
    for (let i = 0; i < eventboxId.length; i++) {
      if (possibleLetters.includes(eventboxId.charAt(i))) {
        posFound = i;
        break;
      }
    }

    if (posFound > 0) {
      setSelectedHub(eventboxId.substr(0, posFound));
      setRowLetter(eventboxId.substr(posFound, 1));
      setRow(eventboxId.substr(posFound + 1, eventboxId.length));
    }
  }, [eventboxId]);

  const handleRemoveClose = () => {
    if (
      window.confirm(
        "Are you sure you want to remove the space from this SAFEDI?"
      )
    ) {
      onClose(safediId, "--");
    } else {
      close();
    }
  };

  const handleClose = () => {
    const newEventboxId = selectedHub + rowLetter + row;
    if (
      window.confirm(
        "Are you sure you want to assign the new space '" +
          newEventboxId +
          "' to this SAFEDI?"
      )
    ) {
      onClose(safediId, newEventboxId);
    } else {
      close();
    }
  };

  const close = () => {
    onClose(safediId, undefined);
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Edit</DialogTitle>
      <Box pl={3} pr={3} pb={3}>
        <div style={{ paddingBottom: 20 }}>
          <FormControl>
            <InputLabel id="hub-selection-label">Hub</InputLabel>
            <Select
              style={{
                width: 70,
              }}
              labelId="hub-selection-label"
              id="hub-selection"
              value={selectedHub}
              onChange={(event: any) => {
                setSelectedHub(event.target.value);
              }}
            >
              {possibleHubs.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="hub-selection-label">COLUMN</InputLabel>
            <Select
              style={{
                width: 70,
              }}
              labelId="hub-selection-label"
              id="hub-selection"
              value={rowLetter}
              onChange={(event: any) => {
                setRowLetter(event.target.value);
              }}
            >
              {possibleLetters.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="hub-selection-label">ROW</InputLabel>
            <Select
              style={{
                width: 70,
              }}
              labelId="hub-selection-label"
              id="hub-selection"
              value={row}
              onChange={(event: any) => {
                setRow(event.target.value);
              }}
            >
              {possibleRows.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <div
            style={{
              paddingTop: 20,
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              href="#"
              onClick={() => {
                handleRemoveClose();
              }}
            >
              Remove slot
            </a>
          </div>
        </div>
        <Button color="primary" onClick={handleClose}>
          Save
        </Button>
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: "100%",
      marginBottom: 20,
    },
    formControl: {
      minWidth: 120,
      width: "100%",
    },
  })
);
