export enum ServerType {

}

export interface Stat {
    id?: string;
    dateString?: string;
    contactCount?: number;
    syncCount?: number;
}

export interface Device {
    id?: string;
    company?: string;
    deviceId?: string;
    createdAt?: string;
    updatedAt?: string;
    lastContact?: string;
    eventboxId?: string;
}

export interface Hub {
    id?: string;
    company?: string;
    name?: string;
    token?: string;
    lastContact?: string;
}

export interface Company {
    id?: string;
    companyId?: string;
    name?: string;
    serverBaseURL?: string;
}

export interface User {
    id?: string;
    role?: string;
    email?: string;
    name?: string;
    company?: string;
}

export interface CoronaContact {
    id?: string;
    deviceId?: string;
    deviceIdContact?: string;
    companyId?: string;
    zone1?: number;
    zone2?: number;
    date?: string;
}

export interface CoronaContactWarning {
    deviceId: string;
    dates: [string];
    eventboxId?: string;
}

export interface CoronaWarning {
    id?: string;
    warningDeviceIds?: [CoronaContact];
    warningContacts?: [CoronaContactWarning];
    warningZoneDict?: any;
    deviceId?: string;
    safediId?: string;
    createdBy?: string;
    date?: string;
}

export interface Login {
    email: string;
    password: string;
}

export interface LoginResponse {
    success: boolean;
    user: User;
}

export interface LoginState {
    user?: User;
    loading: boolean,
    error?: any
}

export interface DevicesState {
    devices: Device[];
    loading: boolean,
    error?: any
}

export interface CoronaWarningState {
    warnings: CoronaWarning[];
    loading: boolean,
    error?: any
}

export interface HubsState {
    hubs: Hub[];
    loading: boolean,
    error?: any
}

export interface CompaniesState {
    companies: Company[];
    users: User[];
    hubs: Hub[];
    devices: Device[];
    loading: boolean,
    error?: any
}

export enum ActionType {
    LOAD_USERS,
    LOADING_USERS_FAILED,
    USERS_LOADED,
    ADD_COMPANY,
    DELETE_COMPANY,
    LOAD_COMPANIES,
    COMPANY_CREATED,
    COMPANY_CREATION_FAILED,
    COMPANIES_LOADED,
    COMPANIES_FAILD_TO_LOAD,
    ADD_WARNING,
    DELETE_WARNING,
    LOAD_WARNINGS,
    WARNINGS_LOADED,
    WARNINGS_FAILD_TO_LOAD,
    PERFORM_LOGIN,
    LOGIN_SUCCEEDED,
    LOGIN_FAILED,
    PERFORM_LOGOUT,
    LOGOUT_SUCCEEDED,
    LOGOUT_FAILED,
    ADD_DEVICE,
    DELETE_DEVICE,
    LOADING_DEVICES,
    LOAD_DEVICES,
    DEVICES_LOADED,
    DEVICE_UPDATED,
    DEVICES_FAILD_TO_LOAD,
    ADMIN_LOAD_DEVICES,
    ADMIN_DEVICES_LOADED,
    ADMIN_DEVICES_FAILD_TO_LOAD,
    ADMIN_LOAD_HUBS,
    ADMIN_HUBS_LOADED,
    ADMIN_HUBS_FAILD_TO_LOAD,
    LOAD_HUBS,
    HUB_CREATED,
    HUB_CREATION_FAILED,
    HUBS_LOADED,
    HUBS_FAILD_TO_LOAD,
    ADD_HUB,
}

export interface Action<T> {
    type: ActionType;
    payload: T;
}