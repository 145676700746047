import { Button, Grid, Typography, TextField } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { CSVLink } from "react-csv";
import { DevicesTable } from "../components/DevicesTable";
import { AddDeviceDialog } from "../components/AddDeviceDialog";
import { useSelector } from "react-redux";
import { useActions } from "../actions";
import { RootState } from "../reducers";
import * as DeviceActions from "../actions/devices";

export function DeviceManagementPage() {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [filterString, setFilterString] = React.useState("");
	const [lastSyncedFilter, setLastSyncedFilter] = React.useState("all");
	const devicesState = useSelector((state: RootState) => state.devicesState);
	const deviceActions = useActions(DeviceActions);
	let devicesList = devicesState.devices;

	const handleClose = () => {
		setOpen(false);
	};

	const handleAddTodo = () => {
		setOpen(true);
	};

	const getCSVData = () => {
		let devicesArray = [["SAFEDI ID", "SPACE", "Last contact"]];
		for (let i = 0; i < devicesList.length; i++) {
			let safediId = devicesList[i].deviceId ?? "";
			let lastContact = devicesList[i].lastContact?.toString() ?? "";
			let eventboxId = "SPACE_" + (devicesList[i].eventboxId ? devicesList[i].eventboxId : "NOT_DEFINED");
			devicesArray.push([safediId, eventboxId, lastContact]);
		}
		return devicesArray;
	};

	return (
		<Grid container className={classes.root}>
			<AddDeviceDialog open={open} onClose={handleClose} />
			<Grid item xs={6}>
				<Typography variant="h4" gutterBottom>
					SAFEDIs ({devicesList.length})
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<div className={classes.buttonContainer}>
					<TextField
						value={filterString}
						onChange={(event: any) => {
							setFilterString(event.target.value);
						}}
						placeholder="Filter"
					/>
					<div className={classes.exportLink}>
						<CSVLink separator={";"} data={getCSVData()}>Export</CSVLink>
					</div>
					<Button
						className={classes.button}
						variant="contained"
						color="secondary"
						onClick={handleAddTodo}
					>
						Register SAFEDI
					</Button>
				</div>
			</Grid>
			<Grid item xs={12}>
				<DevicesTable filter={filterString} />
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: 40
	},
	exportLink: {
		padding: '8px 20px',
	},
	buttonContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
	},
	button: {
		marginBottom: 30,
	},
}));
