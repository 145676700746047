import createReducer from "./createReducer";
import { Action, ActionType, DevicesState, Device, CompaniesState, Company, User, Hub } from "../model/model";

export const companiesState = createReducer<CompaniesState>({
	companies: [],
	users: [],
	devices: [],
	hubs: [],
	loading: false,
	error: undefined
}, {
	[ActionType.ADMIN_LOAD_HUBS](state: CompaniesState, action: Action<Hub[]>) {
		return {
			...state,
			loading: true,
			hubs: []
		};
	},
	[ActionType.ADMIN_HUBS_FAILD_TO_LOAD](state: CompaniesState, action: Action<Hub[]>) {
		return {
			...state,
			loading: false,
			hubs: []
		};
	},
	[ActionType.ADMIN_HUBS_LOADED](state: CompaniesState, action: Action<Hub[]>) {
		return {
			...state,
			loading: false,
			hubs: action.payload
		};
	},
	[ActionType.ADMIN_LOAD_DEVICES](state: CompaniesState, action: Action<Device[]>) {
		return {
			...state,
			loading: true,
			devices: []
		};
	},
	[ActionType.ADMIN_DEVICES_FAILD_TO_LOAD](state: CompaniesState, action: Action<Device[]>) {
		return {
			...state,
			loading: false,
			devices: []
		};
	},
	[ActionType.ADMIN_DEVICES_LOADED](state: CompaniesState, action: Action<Device[]>) {
		return {
			...state,
			loading: false,
			devices: action.payload
		};
	},
	[ActionType.LOAD_USERS](state: CompaniesState, action: Action<User[]>) {
		return {
			...state,
			loading: true,
			users: []
		};
	},
	[ActionType.LOADING_USERS_FAILED](state: CompaniesState, action: Action<User[]>) {
		return {
			...state,
			loading: false,
			users: []
		};
	},
	[ActionType.USERS_LOADED](state: CompaniesState, action: Action<User[]>) {
		return {
			...state,
			loading: false,
			users: action.payload
		};
	},
	[ActionType.LOAD_COMPANIES](state: CompaniesState, action: Action<Company[]>) {
		return {
			...state,
			loading: true
		};
	},
	[ActionType.LOAD_COMPANIES](state: CompaniesState, action: Action<Company[]>) {
		return {
			...state,
			loading: true
		};
	},
	[ActionType.COMPANY_CREATED](state: CompaniesState, action: Action<Company>) {
		return {
			...state,
			loading: false,
			companies: [...state.companies, action.payload]
		};
	},
	[ActionType.COMPANIES_LOADED](state: CompaniesState, action: Action<Company[]>) {
		return {
			...state,
			companies: action.payload,
			loading: false
		};
	},
	[ActionType.COMPANIES_FAILD_TO_LOAD](state: CompaniesState, action: Action<Company[]>) {
		return {
			...state,
			loading: false
		};
	},
	[ActionType.DELETE_COMPANY](state: CompaniesState, action: Action<Company>) {
		return {
			...state
		};
	}
});