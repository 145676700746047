import API from "../api/api";
import { ActionType, CoronaWarning } from "../model/model";

// Load corona warnings
export function loadCoronaWarnings() {
	return (dispatch: Function, getState: Function) => {
		dispatch({ type: ActionType.LOAD_WARNINGS });

		API.loadCoronaWarnings().then((warnings: CoronaWarning[]) => {
			dispatch({ type: ActionType.WARNINGS_LOADED, payload: warnings });
		}).catch((error: any) => {
			dispatch({ type: ActionType.WARNINGS_FAILD_TO_LOAD, payload: error });
		});
	};
}

// Add new corona warning
export function sendWarning(warning: any) {
	return (dispatch: Function, getState: Function) => {
		API.sendCoronaWarning(warning).then((createdWarning: CoronaWarning) => {
			dispatch(loadCoronaWarnings());
		}).catch((error: any) => {
			alert("Something went wrong. Please check the entered SAFEDI-ID.")
		});
	};
}