import createReducer from "./createReducer";
import { Action, ActionType, User, HubsState, Hub } from "../model/model";

export const hubsState = createReducer<HubsState>({
	hubs: [],
	loading: false,
	error: undefined
}, {
	[ActionType.LOAD_HUBS](state: HubsState, action: Action<Hub[]>) {
		return {
			...state,
			loading: true,
			hubs: []
		};
	},
	[ActionType.HUBS_FAILD_TO_LOAD](state: HubsState, action: Action<User[]>) {
		return {
			...state,
			loading: false,
			hubs: []
		};
	},
	[ActionType.HUBS_LOADED](state: HubsState, action: Action<Hub[]>) {
		return {
			...state,
			loading: false,
			hubs: action.payload
		};
	}
});