import { History } from "history";
import { combineReducers } from "redux";
import * as loginReducer from "./login";
import * as devicesReducer from "./devices";
import * as coronaWarningReducer from "./warning";
import * as companiesReducer from "./companies";
import * as hubsReducer from "./hubs";
import { LoginState, CoronaWarningState, DevicesState, CompaniesState, HubsState } from "../model/model";

export interface RootState {
	coronaWarningState: CoronaWarningState;
	devicesState: DevicesState;
	loginState: LoginState;
	companiesState: CompaniesState;
	hubsState: HubsState;
}

export default (history: History) => {
	return combineReducers({
		...loginReducer,
		...coronaWarningReducer,
		...devicesReducer,
		...companiesReducer,
		...hubsReducer
	});
};