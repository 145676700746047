import API from "../api/api";
import { ActionType, Company } from "../model/model";

// Load companies
export function loadCompanies() {
	return (dispatch: Function, getState: Function) => {
		dispatch({ type: ActionType.LOAD_COMPANIES });

		API.loadCompanies().then((companies: Company[]) => {
			dispatch({ type: ActionType.COMPANIES_LOADED, payload: companies });
		}).catch((error: any) => {
			dispatch({ type: ActionType.COMPANIES_FAILD_TO_LOAD, payload: error });
		});
	};
}

// Create company
export function createCompany(company: Company) {
	return (dispatch: Function, getState: Function) => {
		dispatch({ type: ActionType.LOAD_COMPANIES });

		API.createCompany(company).then((company: Company) => {
			dispatch({ type: ActionType.COMPANY_CREATED, payload: company });
		}).catch((error: any) => {
			dispatch({ type: ActionType.COMPANY_CREATION_FAILED, payload: error });
		});
	};
}

// Create company
export function deleteCompany(companyId: string) {
	return (dispatch: Function, getState: Function) => {
		dispatch({ type: ActionType.LOAD_COMPANIES });

		API.deleteCompany(companyId).then(() => {
			dispatch(loadCompanies());
		}).catch((error: any) => {

		});
	};
}

// Delete user
export function deleteUser(userId: string, companyId: string) {
	return (dispatch: Function, getState: Function) => {
		dispatch({ type: ActionType.LOAD_COMPANIES });

		API.deleteUser(userId).then(() => {
			dispatch(loadUsersForCompany(companyId));
		}).catch((error: any) => {

		});
	};
}

// Load users for company
export function createUser(name: string, email: string, password: string, companyId: string) {
	return (dispatch: Function, getState: Function) => {
		API.createUser(name, email, password, companyId).then((company: Company) => {
			dispatch(loadUsersForCompany(companyId));
		}).catch((error: any) => {

		});
	};
}

// Load users for company
export function loadUsersForCompany(companyId: string) {
	return (dispatch: Function, getState: Function) => {
		dispatch({ type: ActionType.LOAD_USERS });

		API.loadUsersForCompany(companyId).then((company: Company) => {
			dispatch({ type: ActionType.USERS_LOADED, payload: company });
		}).catch((error: any) => {
			dispatch({ type: ActionType.LOADING_USERS_FAILED, payload: error });
		});
	};
}

// Delete device
export function updateDevice(companyId: string, deviceId: string, eventboxId: string) {
	return (dispatch: Function, getState: Function) => {
	  API.updateDeviceAdmin(deviceId, eventboxId)
		.then(() => {
		  dispatch(loadDevicesForCompany(companyId));
		})
		.catch((error: any) => {});
	};
  }
  
// Load users for company
export function loadDevicesForCompany(companyId: string) {
	return (dispatch: Function, getState: Function) => {
		dispatch({ type: ActionType.ADMIN_LOAD_DEVICES });

		API.loadDevicesForCompany(companyId).then((company: Company) => {
			dispatch({ type: ActionType.ADMIN_DEVICES_LOADED, payload: company });
		}).catch((error: any) => {
			dispatch({ type: ActionType.ADMIN_DEVICES_FAILD_TO_LOAD, payload: error });
		});
	};
}

// Load users for company
export function loadHubsForCompany(companyId: string) {
	return (dispatch: Function, getState: Function) => {
		dispatch({ type: ActionType.ADMIN_LOAD_HUBS });

		API.loadHubsForCompany(companyId).then((company: Company) => {
			dispatch({ type: ActionType.ADMIN_HUBS_LOADED, payload: company });
		}).catch((error: any) => {
			dispatch({ type: ActionType.ADMIN_HUBS_FAILD_TO_LOAD, payload: error });
		});
	};
}
