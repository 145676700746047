// prettier-ignore
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/styles";
import * as HubActions from "../actions/hubs";
import * as React from "react";
import { useSelector } from "react-redux";
import { useActions } from "../actions";
import { RootState } from "../reducers";
import { Device, Hub } from "../model/model";
import Moment from 'react-moment';

export function HubsTable() {
	const classes = useStyles();
	const hubsState = useSelector((state: RootState) => state.hubsState);
	const hubActions = useActions(HubActions);
	let hubsList = hubsState.hubs;

	// Use only once.
	React.useEffect(function() {
		hubActions.loadHubs();
	}, []);

	if (!hubsList || hubsList.length == 0) {
		return (<div>
			No SAFEDI Synchro Hubs registered yet. New to the SAFEDI Synchro Hub? <a href="https://safedi.com/en/safedi-synchro-hub/" target="_blank">Learn more!</a>
		</div>);
	}

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
				<TableRow>
					<TableCell>Name</TableCell>
					<TableCell>Last synchronized</TableCell>
					<TableCell>SAFEDI Synchro Hub Token</TableCell>
				</TableRow>
				</TableHead>
				<TableBody>
				{hubsList.map((hub: Hub) => (
					<TableRow key={hub.id}>
						<TableCell component="th" scope="row">
							{hub.name}
						</TableCell>
						<TableCell component="th" scope="row">
							{hub.lastContact ? <Moment format="DD.MM.YYYY">{hub.lastContact}</Moment> : "--" }
						</TableCell>
						<TableCell component="th" scope="row">
							{hub.token}
						</TableCell>
					</TableRow>
				))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

const useStyles = makeStyles({
	paper: {
		width: "100%",
		minWidth: 260,
		display: "inline-block",
	},
	table: {
		width: "100%",
	},
	root: {
	  minWidth: 275,
	},
	status: {
		marginBottom: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start"
	},
	statusText: {
		marginLeft: 0
	},
	online: {
		width: 18,
		height: 18,
		borderRadius: 18,
		backgroundColor: '#6DD400'
	},
	offline: {
		width: 18,
		height: 18,
		borderRadius: 18,
		backgroundColor: '#E02020'
	},
	title: {
	  fontSize: 14,
	},
	pos: {
	  marginBottom: 12,
	},
});
