import createReducer from "./createReducer";
import { LoginState, ActionType, Action, User } from "../model/model";

export const loginState = createReducer<LoginState>({
	user: undefined,
	loading: false,
	error: undefined
}, {
	[ActionType.PERFORM_LOGIN]() {
		return {
			user: undefined,
			loading: true,
			error: undefined
		};
	},
	[ActionType.LOGIN_FAILED](state: LoginState, action: Action<any>) {
		return {
			user: undefined,
			loading: false,
			error: action.payload
		};
	},
	[ActionType.LOGIN_SUCCEEDED](state: LoginState, action: Action<User>) {
		return {
			user: action.payload,
			loading: false,
			error: undefined
		};
	},
	[ActionType.PERFORM_LOGOUT](state: LoginState) {
		return {
			loading: true
		};
	},
	[ActionType.LOGOUT_SUCCEEDED](state: LoginState) {
		return {
			user: undefined,
			loading: false,
			error: undefined
		};
	},
	[ActionType.LOGOUT_FAILED](state: LoginState, action: Action<User>) {
		return {
			loading: false,
			error: action.payload,
			user: undefined
		};
	},
});
