// prettier-ignore
import { Button, Dialog, DialogActions, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem, Theme, Box, Checkbox, FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import * as React from "react";
import { useActions } from "../actions";
import * as CoronaWarningActions from "../actions/warning";
import { CoronaWarning } from "../model/model";

interface Props {
  open: boolean;
  onClose: () => void;
}

export function CoronaWarningsDialog(props: Props) {
  const { open, onClose } = props;
  const classes = useStyles();
  const [safediId, setSafediId] = React.useState("");
  const [daysBack, setDaysBack] = React.useState(14);
  const [sendPushNotifications, setSendPushNotifications] = React.useState(
    false
  );
  const coronaWarningActions = useActions(CoronaWarningActions);

  const handleClose = () => {
    onClose();
    setSafediId("");
    setDaysBack(14);
  };

  const reportCorona = () => {
    if (safediId.length <= 0) {
      alert(
        "Please enter the SAFEDI-ID which is tested positive against COVID19."
      );
      return;
    }

    if (safediId.length < 12) {
      alert(
        "The entered SAFEDI-ID is not valid! Please enter the 12 or 15 digits SAFEDI-ID."
      );
      return;
    }

    let warningInfo =
      "Attention: All app users will receive a push-notification. Abuse will be prosecuted.";
    if (sendPushNotifications == false) {
      warningInfo = "Are you sure you want to report a new infection?";
    }
    
    if (window.confirm(warningInfo)) {
      let warning: any = {
        safediId: safediId,
        sendPushNotifications: sendPushNotifications,
        daysBack: daysBack,
      };
      coronaWarningActions.sendWarning(warning);

      onClose();
      setSafediId("");
      setDaysBack(14);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Report new infection</DialogTitle>
      <Box pl={3} pr={3}>
        <p
          style={{
            fontSize: "1rem",
          }}
        >
          Please enter the SAFEDI-ID, which is tested positive against COVID19.
        </p>
        <TextField
          value={safediId}
          onChange={(event: any) => {
            setSafediId(event.target.value);
          }}
          placeholder="SAFEDI-ID"
          className={classes.textField}
        />
      </Box>
      <Box
        pl={3}
        pr={3}
        style={{
          marginTop: 0,
          marginBottom: 30,
        }}
      >
        <p
          style={{
            fontSize: "1rem",
          }}
        >
          How many days back do you want to include? (from today)
        </p>
        <FormControl>
          <Select
            style={{
              width: 250,
            }}
            labelId="hub-selection-label"
            id="hub-selection"
            value={daysBack}
            onChange={(event: any) => {
              setDaysBack(event.target.value);
            }}
          >
            <MenuItem value={1}>1 day</MenuItem>
            <MenuItem value={2}>2 days</MenuItem>
            <MenuItem value={3}>3 days</MenuItem>
            <MenuItem value={4}>4 days</MenuItem>
            <MenuItem value={5}>5 days</MenuItem>
            <MenuItem value={6}>6 days</MenuItem>
            <MenuItem value={7}>7 days</MenuItem>
            <MenuItem value={8}>8 days</MenuItem>
            <MenuItem value={9}>9 days</MenuItem>
            <MenuItem value={10}>10 days</MenuItem>
            <MenuItem value={11}>11 days</MenuItem>
            <MenuItem value={12}>12 days</MenuItem>
            <MenuItem value={13}>13 days</MenuItem>
            <MenuItem value={14}>14 days</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box pl={3} pr={3}>
        <FormControlLabel
          control={
            <Switch
              checked={sendPushNotifications}
              onChange={(event: any) => {
                setSendPushNotifications(event.target.checked);
              }}
            />
          }
          label="Send push-notifications to all app users."
        />
      </Box>
      <DialogActions>
        <Button color="primary" onClick={reportCorona}>
          Report infection
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: "100%",
      marginBottom: 20,
    },
    formControl: {
      minWidth: 120,
      width: "100%",
    },
  })
);
