// prettier-ignore
import { Button, Dialog, DialogActions, DialogTitle, TextField, Box, Typography, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useActions } from "../actions";
import * as LoginActions from "../actions/login";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";

interface Props {

}

export function LoginForm(props: Props) {
	const {} = props;
	const classes = useStyles();
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
    const loginActions = useActions(LoginActions);
	const loginState = useSelector((state: RootState) => state.loginState);
    
	const performLogin = () => {
		loginActions.performLogin({
            email,
            password
		});
	};

	return (
        <form>
            <Typography variant="h5" gutterBottom>
                Login
            </Typography>
            <Box>
                <TextField
                    id="email"
                    label="Email"
                    variant="filled"
                    autoComplete="username"
                    value={email}
                    onChange={(event: any) => {
                        setEmail(event.target.value);
                    }}
                    className={classes.textField}
                />
            </Box>
            <Box>
                <TextField
                    id="password"
                    label="Passwort"
                    type="password"
                    autoComplete="current-password"
                    variant="filled"
                    value={password}
                    onChange={(event: any) => {
                        setPassword(event.target.value);
                    }}
                    className={classes.textField}
                />
            </Box>
            { loginState.error && <Box mt={2}>Login failed! Please check your email and password.</Box> }
            <Box mt={2}>
                <Button variant="contained" color="primary" onClick={performLogin}>
                    Login
                </Button>
            </Box>
        </form>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            minWidth: 280,
		},
	},
}));
