import {
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import * as CompanyActions from "../actions/companies";
import { AddUserDialog } from "../components/AddUserDialog";
import { Company, User, Device, Hub } from "../model/model";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import { useActions } from "../actions";
import DeleteIcon from "@material-ui/icons/Delete";
import CSVReader from "react-csv-reader";
import api from "../api/api";
import { AddHubDialogAdmin } from "../components/AddHubDialogAdmin";
import Moment from "react-moment";
import EditIcon from "@material-ui/icons/Edit";
import { EditPlaceIdDialog } from "../components/EditPlaceIdDialog";

export function CompanyDetailPage() {
  const classes = useStyles();
  const history = useHistory();
  const companyActions = useActions(CompanyActions);
  const companiesState = useSelector(
    (state: RootState) => state.companiesState
  );
  const [open, setOpen] = React.useState(false);
  const [openHub, setOpenHub] = React.useState(false);
  const [eventboxId, setEventboxId] = React.useState("");
  const [safediId, setSafediId] = React.useState("");
  const [openEditSafedi, setOpenEditSafedi] = React.useState(false);

  // Use only once.
  React.useEffect(function () {
    companyActions.loadUsersForCompany(company.id);
    companyActions.loadDevicesForCompany(company.id);
    companyActions.loadHubsForCompany(company.id);
  }, []);

  let historyState: any = history.location?.state;
  if (!historyState || !historyState.company) {
    return <div></div>;
  }

  let company: Company = historyState.company;
  let users: User[] = companiesState.users;
  let devices: Device[] = companiesState.devices;
  let hubs: Hub[] = companiesState.hubs;

  const handleCloseHub = () => {
    setOpenHub(false);
    companyActions.loadHubsForCompany(company.id);
  };

  const handleAddHub = () => {
    setOpenHub(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseEditSafedi = (safediId: string, eventboxId?: string) => {
    setOpenEditSafedi(false);
    if (eventboxId !== undefined) {
      companyActions.updateDevice(company.id, safediId, eventboxId);
    }
  };

  const handleAddTodo = () => {
    setOpen(true);
  };

  const papaparseOptions = {
    header: true,
    skipEmptyLines: true,
  };

  const createHub = () => {};

  const handleDeleteAllDevices = () => {
    api.deleteAllDevices(company.id ?? "").then(() => {
      companyActions.loadDevicesForCompany(company.id);
    });
  };

  const onFileLoaded = (data: Array<any>, fileInfo: any) => {
    let deviceIds: {
      deviceId: string;
      nfcTag?: string;
      eventboxId?: string;
      code?: string;
      crc?: string;
    }[] = [];

    if (data) {
      for (let i = 0; i < data.length; i++) {
        let row = data[i];
        if (row && row["FREMDSNK"] && row["FREMDSNK"].length == 12) {
          let fullCode = row["CODE"];
          let code = undefined;
          let crc = undefined;
          if (fullCode) {
            let codeSplitted = fullCode.split(";");
            if (codeSplitted && codeSplitted.length == 3) {
              code = codeSplitted[1];
              crc = codeSplitted[2];
            }
          }

          deviceIds.push({
            deviceId: row["FREMDSNK"],
            nfcTag: row["RFID"],
            eventboxId: row["EVENTBOXID"],
            code: code,
            crc: crc,
          });
        }
      }
    }

    console.log(deviceIds);
    if (company && company.id) {
      api.registerDevices(deviceIds, company.id).then(() => {
        alert(deviceIds.length + " devices imported.");
        companyActions.loadDevicesForCompany(company.id);
      });
    }
  };

  return (
    <Grid container className={classes.root}>
      <AddHubDialogAdmin
        company={company.id ?? ""}
        open={openHub}
        onClose={handleCloseHub}
      />
      <AddUserDialog
        open={open}
        onClose={handleClose}
        companyId={company.id!}
      />
      <Grid item xs={6}>
        <Typography variant="h4" gutterBottom>
          {company.name}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 40 }}>
        <div className={classes.warningContainerHeader}>
          <div className={classes.status}>
            <b>Company-ID: </b>{" "}
            <Typography className={classes.statusText} color="textSecondary">
              {company.companyId}
            </Typography>
          </div>
          <div className={classes.status}>
            <b>Server-URL: </b>{" "}
            <Typography className={classes.statusText} color="textSecondary">
              {company.serverBaseURL}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom>
          Users ({users.length})
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={handleAddTodo}
          >
            Add user
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 40 }}>
        <TableContainer
          style={{
            height: 300,
          }}
          component={Paper}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user: User) => (
                <TableRow key={user.id}>
                  <TableCell component="th" scope="row">
                    {user.name}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          if (
                            window.confirm(
                              "Do you really want to delete this user?"
                            )
                          ) {
                            companyActions.deleteUser(user.id, company.id);
                          }
                        }}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom>
          Devices ({devices && devices.length})
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <CSVReader
          accept=".csv, .txt"
          cssClass="csv-reader-input"
          label="Import list of SAFEDI-IDs"
          onFileLoaded={onFileLoaded}
          parserOptions={papaparseOptions}
          inputId="SAFEDIIDList"
        />
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={handleDeleteAllDevices}
          >
            Delete all devices
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 40 }}>
        <TableContainer
          style={{
            height: 300,
          }}
          component={Paper}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>SAFEDI-ID</TableCell>
                <TableCell>Space</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices?.map((device: Device) => (
                <TableRow key={device.id}>
                  <TableCell component="th" scope="row">
                    {device.deviceId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {device.eventboxId ? device.eventboxId : "--"}{" "}
                    <EditIcon
                      className="editIcon"
                      onClick={() => {
                        setSafediId(device.deviceId ? device.deviceId : "--");
                        setEventboxId(
                          device.eventboxId ? device.eventboxId : "--"
                        );
                        setOpenEditSafedi(true);
                      }}
                    ></EditIcon>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom>
          Hubs ({hubs && hubs.length})
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={handleAddHub}
          >
            Hub anlegen
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 40 }}>
        <TableContainer
          style={{
            height: 300,
          }}
          component={Paper}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Last synchronized</TableCell>
                <TableCell>Hub Token</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hubs?.map((hub: Hub) => (
                <TableRow key={hub.id}>
                  <TableCell component="th" scope="row">
                    {hub.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {hub.lastContact ? (
                      <Moment format="DD.MM.YYYY">{hub.lastContact}</Moment>
                    ) : (
                      "--"
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {hub.token}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <EditPlaceIdDialog
        safediId={safediId}
        eventboxId={eventboxId}
        open={openEditSafedi}
        onClose={handleCloseEditSafedi}
      ></EditPlaceIdDialog>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 40,
  },
  warningContainerHeader: {
    marginBottom: 20,
  },
  table: {
    width: "100%",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  status: {
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  statusText: {
    marginLeft: 5,
  },
  button: {},
}));
