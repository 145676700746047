// prettier-ignore
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as CoronaWarningActions from "../actions/warning";
import * as React from "react";
import { useSelector } from "react-redux";
import { useActions } from "../actions";
import { RootState } from "../reducers";
import { CoronaWarning } from "../model/model";
import { history } from "../configureStore";
import moment from 'moment';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export function CoronaWarningsTable() {
	const classes = useStyles();
	const coronaWarningState = useSelector((state: RootState) => state.coronaWarningState);
	const coronaWarningActions = useActions(CoronaWarningActions);
	let warningsList = coronaWarningState.warnings;

	// Use only once.
	React.useEffect(function () {
		coronaWarningActions.loadCoronaWarnings();
	}, []);

	if (!warningsList || warningsList.length == 0) {
		return (<div>
			No infections reported yet.
		</div>);
	}

	return (
		<Grid container spacing={3}>
			{warningsList.map((warning: CoronaWarning) => {
				return (
					<Grid item xs={12} md={6}>
						<Card className={classes.root}>
							<CardContent onClick={() => {
								history.push('coronawarning/' + warning.id, {
									warning
								});
							}}>
								<div style={{
									flexDirection: 'row',
									display: 'flex',
									alignItems: 'center'
								}}>
									<div style={{
										flexGrow: 1
									}}>
										<div className={classes.status}>
											Infected SAFEDI-ID: <b>{warning.deviceId}</b>
										</div>
										<div>
										Reported at {moment(new Date(warning.date ? warning.date : new Date())).format("DD.MM.YYYY HH:mm")}
										</div>
										<div className={classes.status}>
											{warning.warningDeviceIds?.length} close contacts
									</div>
									</div>
									<div>
										<ArrowForwardIosIcon style={{
											color: '#69b331',
											cursor: 'pointer'
										}} />
									</div>
								</div>
							</CardContent>
						</Card>
					</Grid>
				);
			})}
		</Grid>
	);
}

const useStyles = makeStyles({
	paper: {
		width: "100%",
		minWidth: 260,
		display: "inline-block",
	},
	table: {
		width: "100%",
	},
	root: {
		minWidth: 275,
		cursor: 'pointer'
	},
	status: {
		marginBottom: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start"
	},
	statusText: {
		marginLeft: 5
	},
	online: {
		width: 18,
		height: 18,
		borderRadius: 18,
		backgroundColor: '#6DD400'
	},
	offline: {
		width: 18,
		height: 18,
		borderRadius: 18,
		backgroundColor: '#E02020'
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});
