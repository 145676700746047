import { CssBaseline } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
	palette: {
		primary: {
			light: "#9ce662",
			main: "#69b331",
			dark: "#358300",
			contrastText: "#fff",
		},
		secondary: {
			light: "#9ce662",
			main: "#69b331",
			dark: "#358300",
			contrastText: "#fff",
		},
		text: {
			primary: '#666'
		}
	},
	overrides: {
		MuiTableRow: {
			head: {
				fontWeight: 'bold',
				"& > th ": {
					fontWeight: 'bold',
				}
			}
		},
		MuiTableContainer: {
			root: {
				height: 'calc(100vh - 300px)',
				overflowY: 'scroll',
				border: '#eee solid 1px'
			}
		},
		MuiCard: {
			root: {
				border: '#eee solid 1px'
			}
		}
	},
	shadows: [
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
		'none',
	],
	typography: {
		fontFamily: [
			'Open Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		h4: {
			fontWeight: 'bold'
		},
		h6: {
			fontWeight: 'bold'
		},
		button: {
			fontWeight: 'bold'
		}
	},
});

export function withRoot(Component: any) {
	function WithRoot(props: object) {
		// MuiThemeProvider makes the theme available down the React tree
		// thanks to React context.
		return (
			<ThemeProvider theme={theme}>
				{/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
				<CssBaseline />
				<Component {...props} />
			</ThemeProvider>
		);
	}

	return WithRoot;
}
