// prettier-ignore
import { Button, Dialog, DialogActions, DialogTitle, TextField, Theme, Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import * as React from "react";
import { useActions } from "../actions";
import * as HubActions from "../actions/hubs";

interface Props {
    open: boolean;
    onClose: () => void;
    company: string;
}

export function AddHubDialogAdmin(props: Props) {
    const { open, onClose, company } = props;
    const classes = useStyles();
    const [created, setCreated] = React.useState(false);
    const [name, setName] = React.useState("");
    const hubActions = useActions(HubActions);

    const handleClose = () => {
        onClose();
        setName("");
        setCreated(false);
    };

    const closeDialog = () => {
        setName("");
        setCreated(false);
        onClose();
    };

    const createHub = () => {
        if (name.length <= 0) {
            alert("Please provide a name and email address.");
            return;
        }

        hubActions.createHubAdmin(name, company);

        setCreated(true);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Register new hub</DialogTitle>
            <Box pl={3} pr={3}>
                { created ? <div>
                    <p>You have successfully registered a new Hub. You can now enter the SAFEDI Synchro Hub Token in the settings of the SAFEDI Synchro Hub App.</p>
                </div> : <div>
                    <p>Please enter a name for the new Hub you want to register.</p>
                    <TextField
                        value={name}
                        onChange={(event: any) => {
                            setName(event.target.value);
                        }}
                        placeholder="Name"
                        className={classes.textField}
                    />
                </div> }
            </Box>
            <DialogActions>
                {created ? 
                <Button color="primary" onClick={closeDialog}>
                    Done
				</Button> : 
                <Button color="primary" onClick={createHub}>
                    Add hub
				</Button>}
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            width: "100%",
            marginBottom: 20
        },
        table: {
            width: "100%",
        },
        formControl: {
            minWidth: 120,
            width: "100%"
        },
    }),
);