// prettier-ignore
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/styles";
import * as CompanyActions from "../actions/companies";
import * as React from "react";
import { useSelector } from "react-redux";
import { useActions } from "../actions";
import { RootState } from "../reducers";
import { Company } from "../model/model";
import { history } from "../configureStore";
import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip, IconButton } from '@material-ui/core';

export function CompaniesTable() {
	const classes = useStyles();
	const companiesState = useSelector((state: RootState) => state.companiesState);
	const companyActions = useActions(CompanyActions);
	let companiesList = companiesState.companies;

	// Use only once.
	React.useEffect(function() {
		companyActions.loadCompanies();
	}, []);

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
				<TableRow>
					<TableCell>Name</TableCell>
					<TableCell align="right">Company-ID</TableCell>
					<TableCell align="right">Server-URL</TableCell>
					<TableCell align="right">Actions</TableCell>
				</TableRow>
				</TableHead>
				<TableBody>
				{companiesList.map((company: Company) => (
					<TableRow key={company.id} style={{
						cursor: 'pointer'
					}} onClick={() => {
						history.push('company/' + company.id, {
							company
						});
					}}>
						<TableCell component="th" scope="row">
							{company.name}
						</TableCell>
						<TableCell align="right">{company.companyId}</TableCell>
						<TableCell align="right">{company.serverBaseURL}</TableCell>
						<TableCell align="right">
							<Tooltip title="Delete">
								<IconButton onClick={() => {
									if (window.confirm("Do you really want to delete this company?")) {
										companyActions.deleteCompany(company.id);
									}
								}} aria-label="delete">
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						</TableCell>
					</TableRow>
				))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

const useStyles = makeStyles({
	paper: {
		width: "100%",
		minWidth: 260,
		display: "inline-block",
	},
	table: {
		width: "100%",
	},
	root: {
	  minWidth: 275,
	},
	status: {
		marginBottom: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start"
	},
	statusText: {
		marginLeft: 0
	},
	online: {
		width: 18,
		height: 18,
		borderRadius: 18,
		backgroundColor: '#6DD400'
	},
	offline: {
		width: 18,
		height: 18,
		borderRadius: 18,
		backgroundColor: '#E02020'
	},
	title: {
	  fontSize: 14,
	},
	pos: {
	  marginBottom: 12,
	},
});
