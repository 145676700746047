// prettier-ignore
import { Button, Dialog, DialogActions, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem, Theme, Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import * as React from "react";
import { useActions } from "../actions";
import * as CompanyActions from "../actions/companies";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

interface Props {
    open: boolean;
    onClose: () => void;
    companyId: string;
}

export function AddUserDialog(props: Props) {
    const generatePassword = () => {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const { open, onClose, companyId } = props;
    const classes = useStyles();
    const [created, setCreated] = React.useState(false);
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState(generatePassword());
    const companyActions = useActions(CompanyActions);

    const handleClose = () => {
        onClose();
        setName("");
        setEmail("");
        setCreated(false);
        setPassword(generatePassword());
    };

    const closeDialog = () => {
        setName("");
        setEmail("");
        setCreated(false);
        setPassword(generatePassword());
        onClose();
    };

    const createUser = () => {
        if (name.length <= 0 || email.length <= 0) {
            alert("Please provide a name and email address.");
            return;
        }

        companyActions.createUser(email, name, password, companyId);

        setCreated(true);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create user</DialogTitle>
            <Box pl={3} pr={3}>
                { created ? <div>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="user creation table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Password</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{name}</TableCell>
                                    <TableCell>{email}</TableCell>
                                    <TableCell>{password}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> : <div>
                    <TextField
                        value={name}
                        onChange={(event: any) => {
                            setName(event.target.value);
                        }}
                        placeholder="Name"
                        className={classes.textField}
                    />
                    <TextField
                        value={email}
                        onChange={(event: any) => {
                            setEmail(event.target.value);
                        }}
                        placeholder="Email"
                        className={classes.textField}
                    />
                </div> }
            </Box>
            <DialogActions>
                {created ? 
                <Button color="primary" onClick={closeDialog}>
                    Done
				</Button> : 
                <Button color="primary" onClick={createUser}>
                    Add user
				</Button>}
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            width: "100%",
            marginBottom: 20
        },
        table: {
            width: "100%",
        },
        formControl: {
            minWidth: 120,
            width: "100%"
        },
    }),
);