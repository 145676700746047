// prettier-ignore
import { Dialog, DialogTitle, Theme, Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import * as React from "react";
import { useActions } from "../actions";
import * as DeviceActions from "../actions/devices";
import { CoronaWarning } from "../model/model";
// @ts-ignore
import QrReader from 'react-qr-scanner';

interface Props {
    open: boolean;
    onClose: () => void;
}

export function AddDeviceDialog(props: Props) {
    const { open, onClose } = props;
    const classes = useStyles();
    const deviceActions = useActions(DeviceActions);

    const handleClose = () => {
        onClose();
    };

    const handleScan = (data: any) => {
        if (data) {
            console.log(data);
            let codeParts = data.split(";");
            if (codeParts.length > 2) {
                let deviceId = codeParts[0];
                let signature = codeParts[1];
                
                deviceActions.registerDevice(deviceId, signature);
                handleClose();
            }
        }
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Register SAFEDI</DialogTitle>
            <Box pl={3} pr={3}>
                <p>Please scan the secure SAFEDI QR-Code, which can be found on the bottom of the case.</p>
                <div>
                    <QrReader
                        delay={300}
                        onError={() => {

                        }}
                        onScan={handleScan}
                        style={{
                            width: 300,
                            height: 300,
                            marginBottom: 20,
                            objectFit: 'cover'
                        }}
                    />
                </div>
            </Box>
        </Dialog>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            width: "100%",
            marginBottom: 20
        },
        formControl: {
            minWidth: 120,
            width: "100%"
        },
    }),
);