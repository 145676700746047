import createReducer from "./createReducer";
import { Action, ActionType, DevicesState, Device } from "../model/model";

export const devicesState = createReducer<DevicesState>(
  {
    devices: [],
    loading: false,
    error: undefined,
  },
  {
    [ActionType.DEVICE_UPDATED](
      state: DevicesState,
      action: Action<{
        deviceId: string;
        eventboxId: string;
      }>
    ) {
      const newState = { ...state };
      for (let i = 0; i < newState.devices.length; i++) {
        if (newState.devices[i].deviceId == action.payload.deviceId) {
          newState.devices[i].eventboxId = action.payload.eventboxId;
        }
      }
      return newState;
    },
    [ActionType.LOAD_DEVICES](state: DevicesState, action: Action<Device[]>) {
      return {
        ...state,
        loading: true,
      };
    },
    [ActionType.DEVICES_LOADED](state: DevicesState, action: Action<Device[]>) {
      return {
        ...state,
        devices: action.payload,
        loading: false,
      };
    },
    [ActionType.DEVICES_FAILD_TO_LOAD](
      state: DevicesState,
      action: Action<Device[]>
    ) {
      return {
        ...state,
        loading: false,
      };
    },
    [ActionType.DELETE_DEVICE](state: DevicesState, action: Action<Device>) {
      return {
        ...state,
      };
    },
  }
);
