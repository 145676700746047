// prettier-ignore
import Table from '@material-ui/core/Table';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import * as DeviceActions from "../actions/devices";
import * as React from "react";
import { useSelector } from "react-redux";
import { useActions } from "../actions";
import { RootState } from "../reducers";
import { Device } from "../model/model";
import DeleteIcon from "@material-ui/icons/Delete";
import { Tooltip, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Moment from "react-moment";
import { EditPlaceIdDialog } from "./EditPlaceIdDialog";

export function DevicesTable(props: { filter: string }) {
  const classes = useStyles();
  const devicesState = useSelector((state: RootState) => state.devicesState);
  const deviceActions = useActions(DeviceActions);
  let devicesList = devicesState.devices;
  let [filteredSAFEDIList, setFilteredSAFEDIList] = React.useState([] as any);
  const [open, setOpen] = React.useState(false);
  const [eventboxId, setEventboxId] = React.useState("");
  const [safediId, setSafediId] = React.useState("");

  const handleClose = (safediId: string, eventboxId?: string) => {
    setOpen(false);
    if (eventboxId !== undefined) {
      deviceActions.updateDevice(safediId, eventboxId);
    }
  };

  // Use only once.
  React.useEffect(function () {
    deviceActions.loadDevices();
  }, []);

  React.useEffect(
    function () {
      if (!props.filter || props.filter.length == 0) {
        setFilteredSAFEDIList(devicesList);
        return;
      }

      let filteredList: Device[] = [];
      for (let i = 0; i < devicesList.length; i++) {
        if (devicesList[i] && devicesList[i].deviceId?.includes(props.filter)) {
          filteredList.push(devicesList[i]);
        }
      }
      setFilteredSAFEDIList(filteredList);
    },
    [props.filter, devicesList]
  );

  if (!devicesList || devicesList.length == 0) {
    return <div>No SAFEDIs added yet.</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>SAFEDI-ID</TableCell>
            <TableCell>Space</TableCell>
            <TableCell align="right">Last synchronized</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSAFEDIList.map((device: Device) => (
            <TableRow key={device.deviceId}>
              <TableCell component="th" scope="row">
                {device.deviceId}
              </TableCell>
              <TableCell component="th" scope="row">
                {device.eventboxId ? device.eventboxId : "--"}{" "}
                <EditIcon
                  className="editIcon"
                  onClick={() => {
                    setSafediId(device.deviceId ? device.deviceId : "--");
                    setEventboxId(device.eventboxId ? device.eventboxId : "--");
                    setOpen(true);
                  }}
                ></EditIcon>
              </TableCell>
              <TableCell align="right">
                {device.lastContact ? (
                  <Moment format="DD.MM.YYYY">{device.lastContact}</Moment>
                ) : (
                  "Not synchronized yet"
                )}
              </TableCell>
              <TableCell align="right">
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      deviceActions.deleteDevice(device.id!);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditPlaceIdDialog
        safediId={safediId}
        eventboxId={eventboxId}
        open={open}
        onClose={handleClose}
      ></EditPlaceIdDialog>
    </TableContainer>
  );
}

const useStyles = makeStyles({
  paper: {
    width: "100%",
    minWidth: 260,
    display: "inline-block",
  },
  table: {
    width: "100%",
  },
  root: {
    minWidth: 275,
  },
  status: {
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  statusText: {
    marginLeft: 0,
  },
  online: {
    width: 18,
    height: 18,
    borderRadius: 18,
    backgroundColor: "#6DD400",
  },
  offline: {
    width: 18,
    height: 18,
    borderRadius: 18,
    backgroundColor: "#E02020",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
