import createReducer from "./createReducer";
import { Action, ActionType, CoronaWarningState, CoronaWarning } from "../model/model";

export const coronaWarningState = createReducer<CoronaWarningState>({
	warnings: [],
	loading: false,
	error: undefined
}, {
	[ActionType.LOAD_WARNINGS](state: CoronaWarningState, action: Action<CoronaWarning[]>) {
		return {
			...state,
			loading: true
		};
	},
	[ActionType.WARNINGS_LOADED](state: CoronaWarningState, action: Action<CoronaWarning[]>) {
		return {
			...state,
			warnings: action.payload,
			loading: false
		};
	},
	[ActionType.WARNINGS_FAILD_TO_LOAD](state: CoronaWarningState, action: Action<CoronaWarning[]>) {
		return {
			...state,
			loading: false
		};
	},
	[ActionType.DELETE_WARNING](state: CoronaWarningState, action: Action<CoronaWarning>) {
		return {
			...state
		};
	}
});