// prettier-ignore
import { Divider, List, ListItem, ListItemIcon, ListItemText, Theme } from "@material-ui/core";
import * as React from "react";
import { history } from "..//configureStore";
import { useActions } from "../actions";
import * as LoginActions from "../actions/login";
import TimelineIcon from '@material-ui/icons/Timeline';
import PageviewIcon from '@material-ui/icons/Pageview';
import AdjustIcon from '@material-ui/icons/Adjust';
import BusinessIcon from '@material-ui/icons/Business';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from "@material-ui/styles";
import API from "../api/api";
import logo from "../safedilogo.png";

export function NavigationDrawer(props: {}) {
	const classes = useStyles();
	const loginActions = useActions(LoginActions);
	const [userRole, setUserRole] = React.useState("user");
	const [user, setUser] = React.useState({} as any);

	// Use only once.
	React.useEffect(function () {
		let role = API.getUserRole();
		if (role) {
			setUserRole(role);
		}

		let userObj = API.getUser();
		if (userObj) {
			setUser(userObj);
		}
	}, []);

	return (
		<div>
			<div className={classes.drawerHeader} />
			<div>
				<img style={{
					width: '100%',
					paddingLeft: 50,
					paddingRight: 50,
					marginBottom: 20
				}} src={logo} />
			</div>
			<List>
				{ (user && !user.hideStatistics) && 
				<ListItem button onClick={() => history.push("/")}>
					<ListItemIcon>
						<TimelineIcon />
					</ListItemIcon>
					<ListItemText primary="Statistics" />
				</ListItem> }
				<ListItem button onClick={() => history.push("/coronawarning")}>
					<ListItemIcon>
						<PageviewIcon />
					</ListItemIcon>
					<ListItemText primary="Contact Tracing" />
				</ListItem>
				<ListItem button onClick={() => history.push("/devices")}>
					<ListItemIcon>
						<AdjustIcon />
					</ListItemIcon>
					<ListItemText primary="SAFEDIs" />
				</ListItem>
				<ListItem button onClick={() => history.push("/hubs")}>
					<ListItemIcon>
						<AmpStoriesIcon />
					</ListItemIcon>
					<ListItemText primary="SAFEDI Synchro Hubs" />
				</ListItem>
				{(userRole == 'admin') &&
					<ListItem button onClick={() => history.push("/companies")}>
						<ListItemIcon>
							<BusinessIcon />
						</ListItemIcon>
						<ListItemText primary="Company Management" />
					</ListItem>}
			</List>
			<Divider />
			<List>
				<ListItem button onClick={() => loginActions.logout()}>
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</ListItem>
			</List>
		</div>
	);
}

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		height: "100%",
		zIndex: 1,
		overflow: "hidden",
	},
	appFrame: {
		position: "relative",
		display: "flex",
		width: "100%",
		height: "100%",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		position: "absolute",
	},
	navIconHide: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	drawerHeader: theme.mixins.toolbar,
	drawerPaper: {
		width: 250,
		backgroundColor: theme.palette.background.default,
		[theme.breakpoints.up("md")]: {
			width: drawerWidth,
			position: "relative",
			height: "100%",
		},
	}
}));
