import API from "../api/api";
import { ActionType, Hub } from "../model/model";

// Load hubs
export function loadHubs() {
	return (dispatch: Function, getState: Function) => {
		dispatch({ type: ActionType.LOAD_HUBS });

		API.loadHubs().then((hubs: Hub[]) => {
			dispatch({ type: ActionType.HUBS_LOADED, payload: hubs });
		}).catch((error: any) => {
			dispatch({ type: ActionType.HUBS_FAILD_TO_LOAD, payload: error });
		});
	};
}

// Create hub
export function createHub(name: string) {
	return (dispatch: Function, getState: Function) => {
		API.createHub(name).then((hub: Hub) => {
			dispatch(loadHubs());
		}).catch((error: any) => {
			dispatch({ type: ActionType.HUB_CREATION_FAILED, payload: error });
		});
	};
}

// Create hub
export function createHubAdmin(name: string, company: string) {
	return (dispatch: Function, getState: Function) => {
		API.createHubAdmin(name, company).then((hub: Hub) => {
			dispatch(loadHubs());
		}).catch((error: any) => {
			dispatch({ type: ActionType.HUB_CREATION_FAILED, payload: error });
		});
	};
}