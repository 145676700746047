import { history } from "../configureStore";
import API from "../api/api";
import { Login, ActionType } from "../model/model";

// Logs in the user.
export function performLogin(login: Login) {
	// here you could do API eg
	return (dispatch: Function, getState: Function) => {
		dispatch({ type: ActionType.PERFORM_LOGIN });

		API.login(login).then((user: any) => {
			dispatch({ type: ActionType.LOGIN_SUCCEEDED, payload: user });
			history.push("/");
		}).catch((error: any) => {
			dispatch({ type: ActionType.LOGIN_FAILED, payload: error });
		});
	};
}

// Checks the user's login state.
export function checkLoginState() {
	return (dispatch: Function, getState: Function) => {
		let user = API.getLoggedInUser();
		if (API.isLoggedIn() && user != null) {
			dispatch({ type: ActionType.LOGIN_SUCCEEDED, payload: user });
		} else {
			dispatch({ type: ActionType.LOGIN_SUCCEEDED, payload: null });
			history.push("/login");
		}
	};
}

// Logs the user out
export function logout() {
	return (dispatch: Function, getState: Function) => {
		dispatch({ type: ActionType.PERFORM_LOGOUT });

		API.logout();
		dispatch({ type: ActionType.LOGOUT_SUCCEEDED });
		history.push("/login");
	};
}