import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { LoginForm } from "../components/LoginForm";
import logo from '../assets/logo.png';

export function LoginPage() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.centerContainer}>
				<img className={classes.logo} src={logo} />
				<Card>
					<CardContent>
						<LoginForm />
					</CardContent>
				</Card>
			</div>
		</div>
	);
}

const useStyles = makeStyles({
	root: {
		width: "100%",
		height: "100%",
		textAlign: "center",
		padding: 40
	},
	logo: {
		width: '100%',
		maxWidth: 170,
		marginBottom: 20
	},
	centerContainer: {
		flex: 1,
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	}
});
