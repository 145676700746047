// prettier-ignore
import { AppBar, Badge, Divider, Drawer as DrawerMui, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, useMediaQuery, Drawer } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { Router } from "react-router-dom";
import { history } from "./configureStore";
import { NavigationDrawer } from "./components/NavigationDrawer";
import { RootState } from "./reducers/index";
import { withRoot } from "./withRoot";
import { Routes } from "./Routes";
import { useActions } from "./actions";
import * as LoginActions from "./actions/login";
import { LoginPage } from "./pages/LoginPage";
import 'react-vis/dist/style.css';

function App() {
	const classes = useStyles();
	const [mobileOpen, setMobileOpen] = React.useState(true);
	const loginState = useSelector((state: RootState) => state.loginState);
	const loginActions = useActions(LoginActions);
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("sm")
	);

	// Use only once.
	React.useEffect(function() {
		loginActions.checkLoginState();
	}, []);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	
	if (loginState.user) {
		// User is logged in.
		return (
			<Router history={history}>
				<div className={classes.root}>
					<div className={classes.appFrame}>
						<Hidden mdUp>
							<DrawerMui
								variant="temporary"
								anchor={"left"}
								open={mobileOpen}
								classes={{
									paper: classes.drawerPaper,
								}}
								onClose={handleDrawerToggle}
								ModalProps={{
									keepMounted: true, // Better open performance on mobile.
								}}
							>
								<NavigationDrawer />
							</DrawerMui>
						</Hidden>
						<Hidden smDown>
							<DrawerMui
								variant="permanent"
								open
								classes={{
									paper: classes.drawerPaper,
								}}
							>
								<NavigationDrawer  />
							</DrawerMui>
						</Hidden>
						<Routes />
					</div>
				</div>
			</Router>
		);
	}

	// Not logged in.
	return (
		<Router history={history}>
			<div className={classes.root}>
				<div className={classes.appFrame}>
					<LoginPage />
				</div>
			</div>
		</Router>
	);
}

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		height: "100%",
		zIndex: 1,
		overflow: "hidden",
	},
	appFrame: {
		position: "relative",
		display: "flex",
		width: "100%",
		height: "100%",
		backgroundColor: '#F6F6F6'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		position: "absolute",
	},
	navIconHide: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	drawerHeader: theme.mixins.toolbar,
	drawerPaper: {
		width: 250,
		backgroundColor: 'transparent',
		border: 0,
		[theme.breakpoints.up("md")]: {
			width: drawerWidth,
			position: "relative",
			height: "100%",
		},
	}
}));

export default withRoot(App);
