// prettier-ignore
import { Button, Dialog, DialogActions, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem, Theme, Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import * as React from "react";
import { useActions } from "../actions";
import * as CompanyActions from "../actions/companies";
import { Company } from "../model/model";

interface Props {
    open: boolean;
    onClose: () => void;
}

export function CreateCompanyDialog(props: Props) {
    const { open, onClose } = props;
    const classes = useStyles();
    const [companyName, setCompanyName] = React.useState("");
    const [companyId, setCompanyId] = React.useState("");
    const [serverURL, setServerURL] = React.useState("https://api.safedi.com");
    const companyActions = useActions(CompanyActions);

    const handleClose = () => {
        onClose();
        setCompanyName("");
        setCompanyId("");
        setServerURL("https://api.safedi.com");
    };

    const reportCorona = () => {
        if (companyId.length <= 0) {
            alert("Please enter a company ID.");
            return;
        }
        
        if (companyName.length <= 0) {
            alert("Please enter a company name.");
            return;
        }
        
        if (serverURL.length <= 0) {
            alert("Please enter a server URL.");
            return;
        }
        
        let newServer: Company = {
            name: companyName,
            companyId: companyId,
            serverBaseURL: serverURL
        };
        companyActions.createCompany(newServer);

        onClose();
        setCompanyName("");
        setCompanyId("");
        setServerURL("https://api.safedi.com");
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create company</DialogTitle>
			<Box pl={3} pr={3}>
				<TextField
					value={companyName}
					onChange={(event: any) => {
						setCompanyName(event.target.value);
					}}
					placeholder="Name"
					className={classes.textField}
				/>
                <TextField
					value={companyId}
					onChange={(event: any) => {
						setCompanyId(event.target.value);
					}}
					placeholder="Company-ID"
					className={classes.textField}
				/>
                <TextField
					value={serverURL}
					onChange={(event: any) => {
						setServerURL(event.target.value);
					}}
					placeholder="Server-URL"
					className={classes.textField}
				/>
            </Box>
            <DialogActions>
                <Button color="primary" onClick={reportCorona}>
                    Create company
				</Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            width: "100%",
            marginBottom: 20
        },
        formControl: {
            minWidth: 120,
            width: "100%"
        },
    }),
);