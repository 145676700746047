import { Login, CoronaWarning, Device, Company, Stat } from "../model/model";

const axios = require("axios");
const serverURL = "https://api.safedi.com";

function login(login: Login) {
  return axios
    .post(serverURL + "/v1/auth/login", {
      email: login.email,
      password: login.password,
    })
    .then(function (response: any) {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
        return response.data.user;
      }
      return null;
    });
}

function logout() {
  localStorage.setItem("user", "");
}

function isLoggedIn() {
  let accessToken = getAccessToken();
  if (accessToken) {
    let expires = accessToken.expires;
    if (new Date(expires) > new Date()) {
      return true;
    }
  }
  return false;
}

function getAccessToken() {
  let userData = localStorage.getItem("user");
  if (userData) {
    try {
      let user = JSON.parse(userData);
      if (user.tokens) {
        return user.tokens.access;
      }
    } catch {}
  }
  return null;
}

function getUser() {
  let userData = localStorage.getItem("user");
  if (userData) {
    try {
      let user = JSON.parse(userData);
      if (user.user) {
        return user.user;
      }
    } catch {}
  }
  return null;
}

function getUserRole() {
  let userData = localStorage.getItem("user");
  if (userData) {
    try {
      let user = JSON.parse(userData);
      if (user.user && user.user.role) {
        return user.user.role;
      }
    } catch {}
  }
  return null;
}

function getRequestHeader() {
  let userData = localStorage.getItem("user");
  if (userData) {
    console.log(userData);
    try {
      let user = JSON.parse(userData);
      if (user.tokens && user.tokens.access && user.tokens.access.token) {
        return {
          headers: {
            Authorization: "Bearer " + user.tokens.access.token,
          },
        };
      }
    } catch {}
  }
  return {};
}

function sendCoronaWarning(warning: any) {
  return axios
    .post(
      serverURL + "/v1/warning",
      {
        safediId: warning.safediId,
        sendPushNotifications: warning.sendPushNotifications,
        daysBack: warning.daysBack,
      },
      getRequestHeader()
    )
    .then(function (response: any) {
      return response;
    });
}

function getLoggedInUser() {
  let userData = localStorage.getItem("user");
  if (userData) {
    try {
      let user = JSON.parse(userData);
      if (user.user) {
        return user.user;
      }
    } catch {}
  }
  return null;
}

function loadDevices(): Promise<Device[]> {
  return axios
    .get(serverURL + "/v1/devices", getRequestHeader())
    .then(function (response: any) {
      return response.data;
    });
}

function loadStats(): Promise<Stat[]> {
  return axios
    .get(serverURL + "/v1/stats", getRequestHeader())
    .then(function (response: any) {
      return response.data;
    });
}

function updateDevice(deviceId: string, eventboxId: string) {
  return axios
    .put(
      serverURL + "/v1/devices/" + deviceId,
      {
        eventboxId,
      },
      getRequestHeader()
    )
    .then(function (response: any) {
      return response.data;
    });
}

function updateDeviceAdmin(deviceId: string, eventboxId: string) {
  return axios
    .put(
      serverURL + "/v1/devices/" + deviceId + "/admin",
      {
        eventboxId,
      },
      getRequestHeader()
    )
    .then(function (response: any) {
      return response.data;
    });
}

function deleteDevice(deviceId: string) {
  return axios
    .delete(serverURL + "/v1/devices/" + deviceId, getRequestHeader())
    .then(function (response: any) {
      return response.data;
    });
}

function deleteAllDevices(companyId: string) {
  return axios
    .delete(
      serverURL + "/v1/devices/admin?company=" + companyId,
      getRequestHeader()
    )
    .then(function (response: any) {
      return response.data;
    });
}

function registerDevice(deviceId: string, signature: string) {
  return axios
    .post(
      serverURL + "/v1/devices",
      {
        deviceId,
        signature,
      },
      getRequestHeader()
    )
    .then(function (response: any) {
      return response.data;
    });
}

function registerDevices(devices: any[], companyId: string) {
  return axios
    .post(
      serverURL + "/v1/devices/multi",
      {
        devices,
        companyId,
      },
      getRequestHeader()
    )
    .then(function (response: any) {
      return response.data;
    });
}

function loadCompanies(): Promise<Company[]> {
  return axios
    .get(serverURL + "/v1/companies", getRequestHeader())
    .then(function (response: any) {
      return response.data;
    });
}

function createCompany(company: Company) {
  return axios
    .post(serverURL + "/v1/companies", company, getRequestHeader())
    .then(function (response: any) {
      return response.data;
    });
}

function deleteCompany(companyId: string) {
  return axios
    .delete(serverURL + "/v1/companies/" + companyId, getRequestHeader())
    .then(function (response: any) {
      return response.data;
    });
}

function deleteUser(userId: string) {
  return axios
    .delete(serverURL + "/v1/users/" + userId, getRequestHeader())
    .then(function (response: any) {
      return response.data;
    });
}

function createUser(
  email: string,
  name: string,
  password: string,
  companyId: string
) {
  let userData = {
    email: email,
    name: name,
    password: password,
    company: companyId,
    role: "manager",
  };
  console.log(userData);
  return axios
    .post(serverURL + "/v1/users", userData, getRequestHeader())
    .then(function (response: any) {
      return response.data;
    });
}

function loadHubsForCompany(companyId: string) {
  return axios
    .get(serverURL + "/v1/hub/admin?company=" + companyId, getRequestHeader())
    .then(function (response: any) {
      return response.data;
    });
}

function loadDevicesForCompany(companyId: string) {
  return axios
    .get(
      serverURL + "/v1/devices/admin?company=" + companyId,
      getRequestHeader()
    )
    .then(function (response: any) {
      return response.data;
    });
}

function loadUsersForCompany(companyId: string) {
  return axios
    .get(serverURL + "/v1/users/?company=" + companyId, getRequestHeader())
    .then(function (response: any) {
      return response.data;
    });
}

function loadCoronaWarnings(): Promise<CoronaWarning[]> {
  return axios
    .get(serverURL + "/v1/warning?sortBy=date:desc", getRequestHeader())
    .then(function (response: any) {
      return response.data;
    });
}

function loadHubs(): Promise<Company[]> {
  return axios
    .get(serverURL + "/v1/hub", getRequestHeader())
    .then(function (response: any) {
      return response.data;
    });
}

function createHub(name: string) {
  return axios
    .post(
      serverURL + "/v1/hub",
      {
        name: name,
      },
      getRequestHeader()
    )
    .then(function (response: any) {
      return response.data;
    });
}

function createHubAdmin(name: string, companyId: string) {
  return axios
    .post(
      serverURL + "/v1/hub/admin?company=" + companyId,
      {
        name: name,
      },
      getRequestHeader()
    )
    .then(function (response: any) {
      return response.data;
    });
}

export default {
  login,
  logout,
  isLoggedIn,
  getLoggedInUser,
  loadCoronaWarnings,
  sendCoronaWarning,
  loadDevices,
  deleteDevice,
  deleteAllDevices,
  registerDevice,
  loadCompanies,
  createCompany,
  deleteCompany,
  loadUsersForCompany,
  loadDevicesForCompany,
  loadHubsForCompany,
  getUserRole,
  getUser,
  createUser,
  deleteUser,
  registerDevices,
  loadHubs,
  createHub,
  createHubAdmin,
  loadStats,
  updateDevice,
  updateDeviceAdmin,
};
