import { Button, Grid, Typography, CardContent } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import TimeAgo from 'react-timeago';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CoronaWarningsDialog } from "../components/CoronaWarningsDialog";
import { CoronaContactWarning } from "../model/model";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// @ts-ignore
import ReactExport from 'react-export-excel';
import Moment from 'react-moment';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function CoronaWarningsDetailPage() {
	const classes = useStyles();
	const history = useHistory();
	const [open, setOpen] = React.useState(false);

	let historyState: any = history.location?.state;
	if (!historyState || !historyState.warning) {
		return (<div></div>);
	}

	let warning: any = historyState.warning;

	const handleClose = () => {
		setOpen(false);
	};

	const handleAddTodo = () => {
		setOpen(true);
	};

	const getCSVData = () => {
		let devicesArray = [];
		for (let i = 0; i < warning.warningContacts.length; i++) {
			let contact = warning.warningContacts[i];
			if (contact.dates) {
				for (let j = 0; j < contact.dates.length; j++) {
					const deviceId = warning.deviceId;
					const deviceIdPartner = warning.warningContacts[i].deviceId;
					const date = contact.dates[j];
					const intensity = getIntensity(deviceIdPartner, date);
					devicesArray.push({
						deviceId,
						deviceIdPartner,
						date,
						intensity: getIntensityRange(intensity),
						eventboxId: "SPACE_" + (warning.warningContacts[i].eventboxId ? warning.warningContacts[i].eventboxId : "NOT_DEFINED")
					});
				}
			}
		}
		return devicesArray;
	};

	const getIntensity = (deviceId: string, date: string) => {
		let dateKey = date.replace(".", "").replace(".", "");
		if (!warning.warningZoneDict) {
			return;
		}
		if (!warning.warningZoneDict[deviceId]) {
			return;
		}
		return warning.warningZoneDict[deviceId][dateKey];
	}

	const getIntensityRange = (intensity: number) => {
		if (intensity >= 10) {
			return 'HIGH';
		}
		if (intensity >= 2) {
			return 'MEDIUM';
		}

		return 'LOW';
	}

	const renderIntensity = (deviceId: string, date: string) => {
		const intensity = getIntensity(deviceId, date);
		let intensityColor = "#F7B500";
		if (intensity >= 2) {
			intensityColor = "#FA6400";
		}
		if (intensity >= 10) {
			intensityColor = "#E02020";
		}
		return (<div style={{
			width: 14,
			height: 14,
			borderRadius: 14,
			backgroundColor: intensityColor,
			marginRight: 6
		}}></div>)
	}

	return (
		<Grid container className={classes.root}>
			<CoronaWarningsDialog open={open} onClose={handleClose} />
			<Grid item xs={6}>
				<Typography variant="h4" gutterBottom>
					<ArrowBackIosIcon style={{
						color: '#69b331',
						cursor: 'pointer'
					}} onClick={() => {
						history.goBack();
					}} />
					Reported infection details
				</Typography>
			</Grid>
			<Grid item xs={6} style={{
				display: 'flex',
				justifyContent: 'flex-end'
			}}>
				<ExcelFile element={<Button variant="contained" color="primary">Export as .xlsx</Button>}>
					<ExcelSheet data={getCSVData()} name="Contacts">
						<ExcelColumn label="SAFEDI ID" value="deviceId"/>
						<ExcelColumn label="SAFEDI ID of contact" value="deviceIdPartner"/>
						<ExcelColumn label="Date of contact" value="date"/>
						<ExcelColumn label="Intensity of contact" value="intensity" />
						<ExcelColumn label="SPACE" value="eventboxId"/>
					</ExcelSheet>
				</ExcelFile>
			</Grid>
			<Grid item xs={12}>
				<div className={classes.warningContainerHeader}>
					<div className={classes.status}>
						Infected SAFEDI-ID <b>{warning.deviceId}</b>
					</div>
					<div className={classes.status}>
						Reported at {moment(new Date(warning.date ? warning.date : new Date())).format("DD.MM.YYYY HH:mm")} · <b>{warning.warningDeviceIds?.length}</b> close contacts
					</div>
					<div>
						<div style={{
							display: 'inline-block',
							width: 14,
							height: 14,
							borderRadius: 14,
							backgroundColor: "#F7B500",
							marginRight: 6
						}}></div>1 contact / day | <div style={{
							display: 'inline-block',
							width: 14,
							height: 14,
							borderRadius: 14,
							backgroundColor: "#FA6400",
							marginRight: 6
						}}></div> 2 - 9 contacts / day | <div style={{
							display: 'inline-block',
							width: 14,
							height: 14,
							borderRadius: 14,
							backgroundColor: "#E02020",
							marginRight: 6
						}}></div> >= 10 contacts / day
					</div>
				</div>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>SAFEDI-ID</TableCell>
								<TableCell>Space</TableCell>
								<TableCell>Date of contact</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{warning.warningContacts?.map((warningContact: CoronaContactWarning) => (
								<TableRow key={warning}>
									<TableCell component="th" scope="row">
										{warningContact.deviceId}
									</TableCell>
									<TableCell component="th" scope="row">
										{warningContact.eventboxId}
									</TableCell>
									<TableCell component="th" scope="row">
										{warningContact.dates && warningContact.dates.map((date) => {
											return <div style={{
												display: 'flex'
											}}>
												{renderIntensity(warningContact.deviceId, date)}
												<Moment parse="DD.MM.YYYY" format="DD.MM.YYYY">{date}</Moment>
											</div>;
										})}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: 40
	},
	warningContainerHeader: {
		marginBottom: 20
	},
	table: {
		width: "100%",
	},
	buttonContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
	},
	status: {
		marginBottom: 0
	},
	statusText: {
		marginLeft: 5,
		fontWeight: 'bold'
	},
	button: {
		marginBottom: 30,
	},
}));
