import { Grid, Typography, InputLabel } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  LineSeries,
  makeVisFlexible,
  AreaSeries,
  LabelSeries,
} from "react-vis";
import * as React from "react";
import moment from "moment";
import API from "../api/api";

const dateTicks: Date[] = [];
for (let i = 1; i < 14; i++) {
  let date = new Date();
  date.setDate(new Date().getDate() - i);
  date.setHours(0);
  date.setMinutes(0);
  dateTicks.push(new Date(date));
}

export function StatsPage() {
  const classes = useStyles();

  const [selectedHub, setSelectedHub] = React.useState("nohub");
  const [hubs, setHubs] = React.useState([] as any);
  const [user, setUser] = React.useState({} as any);
  const [chartData, setChartData] = React.useState([] as any);
  const [devicesSyncedData, setDevicesSyncedData] = React.useState([] as any);
  const [contactsPerDeviceData, setContactsPerDeviceData] = React.useState(
    [] as any
  );

  const findStatFromStatsData = (date: string, stats: any) => {
    for (let i = 0; i < stats.length; i++) {
      let stat = stats[i];
      if (stat && stat.dateString == date) {
        return stat;
      }
    }
    return null;
  };

  // Use only once.
  React.useEffect(function () {
    API.loadHubs().then((hubs) => {
      setHubs(hubs);
    });
  }, []);

  // Use only once.
  React.useEffect(function () {
    let userObj = API.getUser();
    if (userObj) {
      setUser(userObj);
    }
  }, []);

  React.useEffect(() => {
    API.loadStats().then((stats) => {
      var dataArray = [];
      var devicesSyncedArray = [];
      var contactsPerDeviceArray = [];
      for (let i = 1; i < 14; i++) {
        let dateLabel = moment(
          new Date(new Date().setDate(new Date().getDate() - i))
        ).format("YYYY-MM-DD");
        let stat = findStatFromStatsData(dateLabel, stats);

        if (stat) {
          let contactCount = stat.contactCount;
          let syncCount = stat.syncCount;
          if (selectedHub != "nohub") {
            if (stat["contactCount_" + selectedHub]) {
              contactCount = stat["contactCount_" + selectedHub];
              syncCount = stat["syncCount_" + selectedHub];
            } else {
              contactCount = 0;
              syncCount = 0;
            }
          }

          dataArray.push({
            x: moment(dateLabel, "YYYY-MM-DD"),
            y: contactCount,
            label: contactCount,
            yOffset: -10,
            style: {
              fill: "#666",
              fontSize: 12,
              fontWeight: "bold",
            },
          });
          devicesSyncedArray.push({
            x: moment(dateLabel, "YYYY-MM-DD"),
            y: syncCount,
            label: syncCount,
            yOffset: -10,
            style: {
              fill: "#666",
              fontSize: 12,
              fontWeight: "bold",
            },
          });
          if (contactCount && syncCount && syncCount > 0) {
            contactsPerDeviceArray.push({
              x: moment(dateLabel, "YYYY-MM-DD"),
              y: contactCount / syncCount,
              label: (contactCount / syncCount).toFixed(1),
              yOffset: -10,
              style: {
                fill: "#666",
                fontSize: 12,
                fontWeight: "bold",
              },
            });
          }
        } else {
          dataArray.push({
            x: moment(dateLabel, "YYYY-MM-DD"),
            y: 0,
            label: 0,
          });
          devicesSyncedArray.push({
            x: moment(dateLabel, "YYYY-MM-DD"),
            y: 0,
            label: 0,
          });
          contactsPerDeviceArray.push({
            x: moment(dateLabel, "YYYY-MM-DD"),
            y: 0,
            label: 0,
          });
        }
      }

      setChartData(dataArray);
      setDevicesSyncedData(devicesSyncedArray);
      setContactsPerDeviceData(contactsPerDeviceArray);
    });
  }, [selectedHub]);

  const axes = React.useMemo(
    () => [
      { primary: true, type: "time", position: "bottom" },
      { type: "linear", position: "left" },
    ],
    []
  );

  const FlexibleXYPlot = makeVisFlexible(XYPlot);

  const renderGrid = () => {
    return (
      <>
        <Grid item xs={6}>
          <Typography variant="h4" gutterBottom>
            Statistics
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <FormControl>
            <InputLabel id="hub-selection-label">Filter</InputLabel>
            <Select
              style={{
                width: 200,
              }}
              labelId="hub-selection-label"
              id="hub-selection"
              value={selectedHub}
              onChange={(event: any) => {
                setSelectedHub(event.target.value);
              }}
            >
              <MenuItem value={"nohub"}>All SAFEDIs</MenuItem>
              {hubs.map((hub: any) => {
                return <MenuItem value={hub.id}>{hub.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <div>
            <div style={{}}>
              <div
                style={{
                  marginBottom: 10,
                }}
              >
                <b>Close contacts per day</b> (SAFEDIs with contacts only)
              </div>
              <FlexibleXYPlot xType="time" height={300}>
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis
                  tickValues={dateTicks}
                  tickLabelAngle={-45}
                  tickFormat={(d) => {
                    return moment(d).format("DD.MM");
                  }}
                />
                <YAxis
                  tickFormat={(d) => {
                    return parseInt(d) + "";
                  }}
                />
                <LabelSeries animation data={chartData} />
                <VerticalBarSeries
                  barWidth={0.1}
                  data={chartData}
                  color="#69b331"
                />
              </FlexibleXYPlot>
            </div>
            <div
              style={{
                marginTop: 40,
              }}
            >
              <div
                style={{
                  marginBottom: 10,
                }}
              >
                <b>Synchronized SAFEDIs per day</b> (SAFEDIs with contacts only)
              </div>
              <FlexibleXYPlot xType="time" height={200}>
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis
                  tickValues={dateTicks}
                  tickLabelAngle={-45}
                  tickFormat={(d) => {
                    return moment(d).format("DD.MM");
                  }}
                />
                <YAxis
                  tickFormat={(d) => {
                    return parseInt(d) + "";
                  }}
                />
                <LineSeries data={devicesSyncedData} color="#69b331" />
                <AreaSeries
                  data={devicesSyncedData}
                  color="#69b331"
                  opacity={0.25}
                  stroke="transparent"
                  style={{}}
                />
                <LabelSeries animation data={devicesSyncedData} />
              </FlexibleXYPlot>
            </div>
            <div
              style={{
                marginTop: 40,
              }}
            >
              <div
                style={{
                  marginBottom: 10,
                }}
              >
                <b>Average close contacts per SAFEDI</b> (SAFEDIs with contacts
                only)
              </div>
              <FlexibleXYPlot xType="time" height={200}>
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis
                  tickValues={dateTicks}
                  tickLabelAngle={-45}
                  tickFormat={(d) => {
                    return moment(d).format("DD.MM");
                  }}
                />
                <YAxis
                  tickFormat={(d) => {
                    return parseInt(d) + "";
                  }}
                />
                <LineSeries data={contactsPerDeviceData} color="#69b331" />
                <AreaSeries
                  data={contactsPerDeviceData}
                  color="#69b331"
                  opacity={0.25}
                  stroke="transparent"
                  style={{}}
                />
                <LabelSeries animation data={contactsPerDeviceData} />
              </FlexibleXYPlot>
            </div>
            <div style={{ marginTop: 50 }}>
              Daily statistics are going to be generated by the end of each day.
            </div>
          </div>
        </Grid>
      </>
    );
  };

  return (
    <Grid container className={classes.root}>
      {user && !user.hideStatistics ? (
        renderGrid()
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Welcome
            </Typography>
          </Grid>
          <Grid item xs={12}>
            Welcome to the SAFEDI secure cloud.
          </Grid>
        </>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 40,
  },

  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginBottom: 30,
  },
}));
